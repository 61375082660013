<script lang="ts">
    import { inertia } from '@inertiajs/svelte';
    import BookmarkIcon from '@/Icons/BookmarkIcon.svelte';
    import ImageIcon from '@/Icons/ImageIcon.svelte';
    import VideoCameraIcon from '@/Icons/VideoCameraIcon.svelte';
    import MusicalNoteIcon from '@/Icons/MusicalNoteIcon.svelte';
    import LockClosedIcon from '@/Icons/LockClosedIcon.svelte';
    import UserIcon from '@/Icons/UserIcon.svelte';
    import ForwardChevron from '@/Icons/ForwardChevron.svelte';

    interface BookmarkType {
        heading: string;
        icon: string;
    }

    export let activeTab: string;
    export let bookmarkTypes: Record<string, BookmarkType>;

    function getRoute(type: string): string {
        return `/my/bookmarks/${type}`;
    }

    const iconComponents: Record<string, any> = {
        bookmarks: BookmarkIcon,
        image: ImageIcon,
        videocam: VideoCameraIcon,
        'musical-notes': MusicalNoteIcon,
        person: UserIcon,
        'lock-closed': LockClosedIcon,
    };
</script>

<div class="card-settings hidden border-b md:block">
    <div class="list-group list-group-sm list-group-flush">
        {#each Object.entries(bookmarkTypes) as [route, setting]}
            <a
                href="{getRoute(route)}"
                use:inertia
                class="list-group-item list-group-item-action flex justify-between {activeTab === route
                    ? 'bg-gray-200'
                    : ''}"
            >
                <div class="flex items-center">
                    <svelte:component this="{iconComponents[setting.icon]}" />
                    <span class="ml-2">{setting.heading}</span>
                </div>
                <div class="flex items-center">
                    <ForwardChevron />
                </div>
            </a>
        {/each}
    </div>
</div>

<div class="md:hidden" id="mobile-settings-nav">
    <div class="border-b border-neutral-200 dark:border-neutral-800">
        <div class="flex overflow-x-auto">
            {#each Object.entries(bookmarkTypes) as [route, setting]}
                <a
                    class="flex flex-col items-center justify-around p-3 transition-colors duration-200 ease-in-out
                               {activeTab === route ? 'bg-neutral-100 dark:bg-neutral-900' : ''}"
                    href="{getRoute(route)}"
                    use:inertia
                >
                    <svelte:component this="{iconComponents[setting.icon]}" class="h-6 w-6" />
                </a>
            {/each}
        </div>
    </div>
</div>
